<template>
  <list-card
    ref="refList"
    :items="items"
    :entity-type="'object'"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortId"
    :title="title"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
    @active="handleActive"
    @selectedItemIds="handleSelectedItemIds"
  >
    <template #item="{select,index, ...props}">
      <object-list-item
        v-testid="`object-list-item-${index}`"
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
        @click:favourite="toggleFavourite(props.item)"
      />
    </template>
    <template #toolbar-selection:extra-items>
      <v-btn icon color="primary">
        <favourite-icon />
      </v-btn>
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import ObjectListItem from './ObjectListItem';
import { useObjectListActions } from './listActions';

import {
  computed,
  onBeforeUnmount,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';

import { useMap } from '@/compositions/map';
import { sortTypes, useSortBy } from '@/compositions/sortBy';
import { useObjectMenu } from '@/modules/objects/compositions/objectMenu';
import { objectService } from '@/modules/objects/api';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { useObjects } from '../../compositions/objects';
import {
  genMenuItemAddToGroup,
  genMenuItemAccess,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemDownload,
  genMenuItemMove,
  genMenuItemMute,
  genMenuItemEnable,
  genMenuItemRemoveFromGroup,
  genMenuItemEditMainGeotag,
  hideIfEmpty
} from '@/utils/list-generators';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { usePopup } from '@/compositions/popup';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';
import router from '@/router';
import {
  OBJECT_LIST_FILTER,
  useObjectFilters
} from '@/modules/objects/compositions/object-filters';
import { useFilter } from '@/compositions/filter';
import { useTypes } from '@/modules/object-types/compositions/types';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { objectPropertyService } from '@/modules/common/api';
import { geotagservice } from '@/modules/geotags/api';

export default {
  name: 'Objects',
  components: {
    ListCard,
    ObjectListItem
  },
  props: {
    groupId: {
      type: [String, undefined],
      default: undefined
    }
  },

  // redirect from object group to objet list if group isn't existed
  beforeRouteEnter(from, to, next) {
    next(vm => {
      if (vm.$props.groupId && !vm.objectGroup) {
        next({
          path: '/objects/list'
        });
      } else {
        next();
      }
    });
  },

  setup(props, { root }) {
    const refList = ref(null);

    const {
      markers: {
        setMarkers,
        selectMarker,
        unselectMarker,
        selectMarkers,
        unselectAllMarkers,
        onAltClickMarker
      }
    } = useMap();

    const { getObjectHistory } = useMedia();

    const popup = usePopup();

    const { groupId } = toRefs(props);

    const { groupById, objectGroupList } = useObjectGroups();

    const { list, listMap } = useObjects();
    const { list: geotagList } = useGeotags();

    const groupList = computed(() => {
      if (!groupId.value) return list.value;
      return list.value.filter(i => i.objectGroupIds.includes(groupId.value));
    });

    const objectGroup = computed(() => groupById(groupId.value));

    const title = computed(() => {
      return `${!groupId.value ? 'Objects' : objectGroup.value?.name}`;
    });

    const getItemRoute = item => ({
      name: 'object_card',
      params: {
        ...root.$route.params,
        objectId: item.id
      },
      query: root.$route.query
    });

    const sorting = [
      {
        id: 'name',
        sortType: sortTypes.abc,
        sortKey: 'name',
        title: 'Name'
      },
      {
        id: 'status',
        sortType: sortTypes.boolean,
        sortKey: 'enabled',
        title: 'Status'
      },
      {
        id: 'alarm',
        sortType: sortTypes.boolean,
        sortKey: 'alarmed',
        title: 'Alarm'
      },
      {
        id: 'color',
        sortType: sortTypes.abc,
        sortKey: 'color',
        title: 'Color'
      },
      {
        id: 'favourites',
        sortType: sortTypes.boolean,
        sortKey: 'favourite',
        title: 'Favourites'
      }
    ];

    const selectedItems = ref([]);

    const getItemActions = object => {
      return useObjectMenu({ object });
    };

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const selectAll = () => {
      refList.value && refList.value.selectAll();
    };

    onBeforeUnmount(() => {
      clearSelection();
      unselectAllMarkers();
    });

    const groupActions = computed(() => [
      genMenuItemAccess(() => {
        popup.open({
          component: () => import('@/components/popup/EditRightsGroup.vue'),
          props: {
            objectIds: selectedItems.value,
            many: true,
            onConfirm: () => {
              clearSelection();
            }
          }
        });
      }),
      genMenuItemMute(() => {}, {
        disabled: true
      }),
      genMenuItemDisable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Disable these objects?',
            onConfirm: () => {
              linkedObjectService.massiveUpdate(
                selectedItems.value.map(item => ({ id: item, enabled: false }))
              );
            }
          }
        });
      }),
      genMenuItemEnable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Enable these objects?',
            onConfirm: () => {
              linkedObjectService.massiveUpdate(
                selectedItems.value.map(item => ({ id: item, enabled: true }))
              );
              clearSelection();
            }
          }
        });
      }),
      genMenuItemMove(() => {
        router.push({
          name: 'object_moving_group',
          params: {
            objectIds: selectedItems.value
          }
        });
        clearSelection();
      }),
      genMenuItemEditMainGeotag(() => {
        popup.open({
          component: () => import('@/components/popup/PopupValueEdit.vue'),
          props: {
            title: 'Edit main geotag',
            select: {
              items: geotagList.value,
              itemText: 'name',
              itemValue: 'id',
              clearable: true
            },
            autocomplete: true,
            onConfirm: async geotagId => {
              const promises = selectedItems.value.map(objectId => {
                const object = listMap.value[objectId];
                const propertyId = object?.positionGeotagIdPropertyId;
                const oldGeotagId = object?.positionGeotagId;

                return (async () => {
                  if (propertyId) {
                    await objectPropertyService.update(propertyId, {
                      value: geotagId
                    });
                    await geotagservice.updateLinkToObject(
                      objectId,
                      oldGeotagId,
                      geotagId
                    );
                  }
                })();
              });

              await Promise.all(promises);
              clearSelection();
            }
          }
        });
      }),
      genMenuItemAddToGroup(() => {
        popup.open({
          component: () =>
            import('@/modules/objects/ui/object-list/PopupAddToGroups.vue'),
          props: {
            selectedItemIds: selectedItems.value
          }
        });
        clearSelection();
      }),
      ...hideIfEmpty(!!groupId.value, [
        genMenuItemRemoveFromGroup(async () => {
          selectedItems.value.forEach(itemId => {
            objectService.unlinkByObjectIds(groupId.value, itemId);
          });
          clearSelection();
        })
      ]),
      genMenuItemDownload(
        () => {
          popup.open({
            component: () =>
              import('@/components/history-list-card/HistoryDateRangePopup'),
            props: {
              onSubmit: async range => {
                const data = {
                  object_ids: selectedItems.value,
                  from: zonedTimeToUtc(
                    startOfDay(parseISO(range.from)),
                    'Etc/UTC'
                  ).toISOString(),
                  to: zonedTimeToUtc(
                    endOfDay(parseISO(range.to)),
                    'Etc/UTC'
                  ).toISOString()
                };
                getObjectHistory(data)
                  .then(res => {
                    downloadBlob(
                      res.data,
                      `PixelMonitor_ObjectsHistory_${range.from}-${range.to}.csv`,
                      res.data.type
                    );
                  })
                  .catch(() => {})
                  .finally(() => {});
              }
            }
          });
        },
        { title: 'Download history' }
      ),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these objects completely?',
            onConfirm: () => {
              linkedObjectService.massiveDelete(
                selectedItems.value.map(item => ({ id: item }))
              );
              clearSelection();
            }
          }
        });
      })
    ]);

    const { list: typeList } = useTypes();
    const { list: geotags } = useGeotags();

    const filters = useObjectFilters({
      objectTypes: typeList,
      objectGroups: objectGroupList,
      geotags
    });

    const { filteredItems, isFilterActive, handleFilterOpen } = useFilter(
      groupList,
      filters,
      OBJECT_LIST_FILTER
    );

    const { sortedItems, handleSort, currentSortId } = useSortBy(
      filteredItems,
      sorting,
      'object'
    );

    const toggleFavourite = ({ id, favourite }) => {
      objectService.setFavourite(id, !favourite);
    };

    const { listActions } = useObjectListActions({ selectAll });

    watch(
      () => filteredItems.value,
      (items = []) => {
        setMarkers(items);
      },
      {
        immediate: true
      }
    );

    const handleActive = ({ id, active }) => {
      active ? selectMarker(id) : unselectMarker(id);
    };

    const handleSelectedItemIds = ids => {
      selectedItems.value = ids;
      selectMarkers(ids);
    };

    onAltClickMarker((ids = []) => {
      ids.forEach(id => {
        selectMarker(id);
        refList.value?.select(id);
      });
    });

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      filters,
      sorting,
      currentSortId,
      handleSort,
      toggleFavourite,
      handleActive,
      handleSelectedItemIds,
      title,
      refList,
      isFilterActive,
      handleFilterOpen,
      objectGroup
    };
  }
};
</script>

<style></style>
